import { template as template_3471c707f96f401c8688a01c94b55822 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3471c707f96f401c8688a01c94b55822(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
