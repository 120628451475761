import { template as template_e5551ea04b444469b25930a58887d116 } from "@ember/template-compiler";
const FKLabel = template_e5551ea04b444469b25930a58887d116(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
