import { template as template_8e45d0c6fe34478eb10dec65922a5533 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_8e45d0c6fe34478eb10dec65922a5533(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
