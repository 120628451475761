import { template as template_6cd71c6653314d46bb1a761671291de4 } from "@ember/template-compiler";
const WelcomeHeader = template_6cd71c6653314d46bb1a761671291de4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
