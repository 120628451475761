import { template as template_311d100dc509426da965c1b69e05400a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_311d100dc509426da965c1b69e05400a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
